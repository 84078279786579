import {
  addCallbackRequest,
  batchCreateSlackChannel,
  cancelBatchMerge,
  downloadBatchMergeHistory,
  fetchAllMergeBatchSuggestions,
  fetchBatchesStartingSoon,
  fetchBatchMergeHistory,
  fetchMergeBatchSuggestions,
  fetchNewPremiumStudentBatch,
  fetchScheduledBatchMerges,
  fetchStudentLearningObjectives,
  fetchTournamentBatchSuggestions,
  fetchUnmergeableBatches,
  getBatchNextSessionDatetime,
  listBatches,
  mergeBatches,
  sendPCMBookingLink,
} from 'api';
import {
  fetchBatchCourse,
  fetchBatchLastCompletedSprint,
  fetchLastProject,
  fetchMyBatchHistory,
  getBatchStudentLatestSprint,
  getpendingBatchChangeRequests,
  sendInsightCallNotification,
  sendPartialPaymentNotification,
} from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import Config from 'config';
import { parse } from 'fecha';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { CourseLate } from './CourseStore';
import { MasteryLearningObjective } from '../MasteryLearningObjective';
import { PageStore } from '../Page';
import { ProjectLite } from '../ProjectStore';
import { SprintLite } from './Sprint';
import User, { Student } from '../User';

const BatchTiming = types.model('BatchTiming', {
  description: types.string,
  start_at: types.string,
  weekday: types.integer,
  time_ist: types.maybe(types.string),
});

export const StudentLearningObjectives = types.model('StudentLearningObjectives', {
  students_mastery: types.map(types.map(types.number)),
  learning_objectives: types.array(MasteryLearningObjective),
});

export const StudentLearningObjectivesLarge = StudentLearningObjectives.named('StudentLearningObjectivesLarge').props({
  answers_to_be_graded: types.map(types.map(types.boolean)),
});

export const BatchLite = types
  .model('BatchLite', {
    name: types.string,
    timing_based_name: types.maybe(types.string),
    level: types.maybe(types.string),
    zoho_name: '',
    subject: '',
    coach: User,
    start_at: types.maybeNull(types.string),
    course: types.maybeNull(types.late(CourseLate)),
    last_project: types.maybeNull(ProjectLite),
    last_completed_sprint: types.maybeNull(SprintLite),
    fetchingProject: false,
    fetchingCourse: false,
    fetchingLastCompletedSprint: false,
  })
  .views((self) => ({
    get coachImage() {
      return self.coach.image ? self.coach.image.image_url : self.coach.avatar.image_url;
    },
  }))
  .actions((self) => ({
    fetchLastProject: flow(function* () {
      self.fetchingProject = true;
      try {
        const response: ApiResponse<any> = yield fetchLastProject(self.name);
        if (response.problem) {
          if (response.status === 404 && response.data?.error && response.data.error.includes('unavailable')) return;
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.last_project = cast(response.data);
      } finally {
        self.fetchingProject = false;
      }
    }),
    fetchCourse: flow(function* () {
      self.fetchingCourse = true;
      try {
        const response: ApiResponse<any> = yield fetchBatchCourse(self.name);
        if (response.problem) {
          if (response.status === 404 && response.data?.error && response.data.error.includes('unavailable')) return;
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.course = cast(response.data);
      } finally {
        self.fetchingCourse = false;
      }
    }),
    fetchLastCompletedSprint: flow(function* () {
      self.fetchingLastCompletedSprint = true;
      try {
        const response: ApiResponse<any> = yield fetchBatchLastCompletedSprint(self.name);
        if (response.problem) {
          if (response.status === 404 && response.data?.error && response.data.error.includes('unavailable')) return;
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.last_completed_sprint = cast(response.data);
      } finally {
        self.fetchingLastCompletedSprint = false;
      }
    }),
  }))
  .views((self) => ({
    get displayName() {
      return `#${self.zoho_name} ${self.timing_based_name || self.name}`;
    },
    get startAtDate() {
      if (!self.start_at) return null;
      return parse(self.start_at, Config.DATETIME_FORMAT);
    },
  }));

export interface IBatchLite extends Instance<typeof BatchLite> {}

export const BatchLiteWithoutCoach = BatchLite.named('BatchLiteWithoutCoach').props({
  coach: types.maybe(User),
});

const BatchChangeRequest = types
  .model('BatchChangeRequest', {
    change_date: types.string,
    new_batch: BatchLiteWithoutCoach,
  })
  .views((self) => ({
    get changeDate() {
      return new Date(self.change_date).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    },
  }));

export interface IBatchChangeRequest extends Instance<typeof BatchChangeRequest> {}

export const BatchStudentLite = types
  .model('BatchStudentLite', {
    student: Student,
    name: types.string,
    gettingLatestSprint: false,
    latestSprint: types.maybe(SprintLite),
    batchChangeRequest: types.maybeNull(BatchChangeRequest),
    loadingBatchChangeRequests: false,
  })
  .actions((self) => ({
    getLatestSprint: flow(function* () {
      self.gettingLatestSprint = true;
      try {
        const response: ApiResponse<any> = yield getBatchStudentLatestSprint(self.name);
        if (response.ok) {
          self.latestSprint = response.data.sprint;
        }
      } finally {
        self.gettingLatestSprint = false;
      }
    }),
    pendingBatchChangeRequests: flow(function* () {
      self.loadingBatchChangeRequests = true;
      try {
        const response: ApiResponse<any> = yield getpendingBatchChangeRequests(self.name);
        if (response.ok) {
          self.batchChangeRequest = response.data.batch_change_request;
        }
      } finally {
        self.loadingBatchChangeRequests = false;
      }
    }),
    sendPCMBookingLink: flow(function* (alternateNumber?: string) {
      const response: ApiResponse<any> = yield sendPCMBookingLink(self.name, alternateNumber);
      if (response.ok) return true;
      return false;
    }),
  }));

export interface IBatchStudentLite extends Instance<typeof BatchStudentLite> {}

export const BatchStudent = BatchStudentLite.named('BatchStudent')
  .props({
    parent_username: types.maybeNull(types.string),
    parent_email: types.maybeNull(types.string),
    timezone: types.maybeNull(types.string),
    last_attended: types.maybeNull(types.string),
    total_sessions_attended: 0,
    loading: false,
    error: types.maybe(types.maybeNull(types.string)),
  })
  .actions((self) => ({
    sendPartialPaymentMessage: flow(function* (alternateNumber?: string) {
      const response: ApiResponse<any> = yield sendPartialPaymentNotification({
        batchStudent: self.name,
        alternateNumber,
      });
      if (response.ok) {
        return true;
      }
      return false;
    }),
    sendPICRemainderMessage: flow(function* (alternateNumber?: string) {
      const response: ApiResponse<any> = yield sendInsightCallNotification({
        batchStudent: self.name,
        alternateNumber,
      });
      if (response.ok) {
        return true;
      }
      return false;
    }),
    clearError() {
      self.error = null;
    },
    addCallbackRequest: flow(function* (date: string) {
      self.error = null;
      self.loading = true;
      const response: ApiResponse<any> = yield addCallbackRequest(self.name, date);
      self.loading = false;
      if (response.status === 400) {
        self.error = response.data.error;
      } else if (response.problem) {
        self.error = 'Unexpected Error';
      }
    }),
  }));

export interface IBatchStudent extends Instance<typeof BatchStudent> {}

export const InactiveBatchStudent = BatchStudent.named('inactiveBatchStudent')
  .props({
    status: types.string,
    status_updated_on: types.string,
  })
  .views((self) => ({
    get isChurned() {
      return self.status === 'Churned';
    },
    get isReAssigned() {
      return self.status === 'Re-Assigned';
    },
  }));

export interface IInactiveBatchStudent extends Instance<typeof InactiveBatchStudent> {}

export const Batch = BatchLite.named('Batch')
  .props({
    name: types.string,
    zoho_name: '',
    start_at: types.maybeNull(types.string),
    timings: types.array(BatchTiming),
    batch_students: types.array(BatchStudent),
    inactive_students: types.array(InactiveBatchStudent),
    timing_based_name: types.string,
    level: '',
    subject: '',
    loading: false,
    studentLearningObjectives: types.optional(StudentLearningObjectivesLarge, {}),
    slack_url: types.maybeNull(types.string),
    creatingSlack: false,
    coach: types.maybeNull(User),
    end_at: types.maybeNull(types.string),
    upcoming_session_start_at: types.maybeNull(types.string),
  })
  .actions((self) => ({
    fetchStudentLearningObjectives: flow(function* (config?: AxiosRequestConfig) {
      self.loading = true;
      try {
        const response = yield fetchStudentLearningObjectives({ lookupValue: self.name }, config);
        if (response.problem) {
          return;
        }
        self.studentLearningObjectives = response.data;
      } finally {
        self.loading = false;
      }
    }),
    createSlack: flow(function* () {
      self.creatingSlack = true;
      try {
        const response = yield batchCreateSlackChannel({ batch_name: self.name });
        if (response.problem) {
          return;
        }
      } finally {
      }
    }),
  }))
  .views((self) => ({
    get displayName() {
      return `#${self.zoho_name} ${self.timing_based_name || self.name}`;
    },
    get startAtDate() {
      if (!self.start_at) return null;
      return parse(self.start_at, Config.DATETIME_FORMAT);
    },
  }));

export interface IBatch extends Instance<typeof Batch> {}

export const SuggestedBatchLite = Batch.named('SuggestedBatchLite')
  .props({
    timing_based_name: types.maybeNull(types.string),
    active_students_count: types.integer,
    sprint_diff: types.integer,
    sprint_title: types.string,
  })
  .views((self) => ({
    get displayName() {
      let sprint_diff_text = '';
      if (self.sprint_diff === 0) {
        sprint_diff_text = ' | Same sprint';
      } else if (self.sprint_diff > 0) {
        sprint_diff_text = ` | ${self.sprint_diff} sprint(s) behind`;
      } else {
        sprint_diff_text = ` | ${-1 * self.sprint_diff} sprint(s) ahead`;
      }
      return `#${self.zoho_name}
        ${self.timing_based_name || self.name}
        (${self.active_students_count} active students ${sprint_diff_text})`;
    },
  }));

export const SuggestedBatchMerge = Batch.named('SuggestedBatchMerge')
  .props({
    timing_based_name: types.maybeNull(types.string),
    level: types.string,
    coach__first_name: types.string,
    coach__last_name: types.string,
    active_students_count: types.integer,
    timings_start_at: types.maybe(types.string),
    unit_id: types.maybeNull(types.integer),
    unit_title: types.maybeNull(types.string),
    sprint_id: types.integer,
    sprint_sequence: types.maybeNull(types.integer),
    sprint_title: types.string,
    week_day: types.maybeNull(types.string),
    is_base_batch: types.boolean,
  })
  .views((self) => ({
    get coachName() {
      return `${self.coach__first_name} ${self.coach__last_name}`;
    },
    get startAt() {
      if (!self.timings_start_at) return null;
      const date = new Date(self.timings_start_at);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
  }));
export interface ISuggestedBatchMerge extends Instance<typeof SuggestedBatchMerge> {}

export const ScheduledBatchMerge = SuggestedBatchMerge.named('ScheduledBatchMerge').props({
  sprint_id: types.maybeNull(types.integer),
  merge_at: types.string,
  batch_merge_id: types.integer,
});
export interface IScheduledBatchMerge extends Instance<typeof ScheduledBatchMerge> {}

export const UnmergeableBatch = SuggestedBatchMerge.named('UnmergeableBatch').props({
  sprint_id: types.maybeNull(types.integer),
  is_base_batch: types.maybeNull(types.boolean),
});
export interface IUnmergeableBatch extends Instance<typeof UnmergeableBatch> {}

export const MergeBatchModel = types
  .model('BatchMergeModel', {
    name: types.string,
    zoho_name: '',
    coach__first_name: types.string,
    coach__last_name: types.string,
    active_students_count: types.integer,
    unit_title: types.maybeNull(types.string),
    sprint_sequence: types.maybeNull(types.integer),
    sprint_title: types.maybeNull(types.string),
  })
  .views((self) => ({
    get coachName() {
      return `${self.coach__first_name} ${self.coach__last_name}`;
    },
  }));
export interface IMergeBatchModel extends Instance<typeof MergeBatchModel> {}

export const BatchMergeModel = types.model('BatchMergeModel', {
  base: MergeBatchModel,
  merged: MergeBatchModel,
  merge_at: types.maybeNull(types.string),
  merged_at: types.maybeNull(types.string),
  cancelled_at: types.maybeNull(types.string),
});
export interface IBatchMergeModel extends Instance<typeof BatchMergeModel> {}

const BatchStore = types
  .model('BatchStore', {
    batches: types.array(Batch),
    seniorCoachBatches: types.array(Batch),
    dropdownBatches: types.array(Batch),
    count: types.optional(types.integer, 0),
    loading: false,
    seniorCoachBatchesLoading: false,
    seniorCoachBatchesLoaded: false,
    can_view_all_batches: false,
    loaded: false,
    page: types.maybe(PageStore),
    batchesStartingSoon: types.array(BatchLite),
    coachPastBatches: types.array(Batch),
    batchStudentLearningObjectives: types.maybeNull(
      StudentLearningObjectives.named('StudentLearningObjectivesWithBatch').props({
        batch: Batch,
      })
    ),
  })
  .actions((self) => ({
    reset() {
      self.loaded = false;
      self.batches.clear();
    },
    listBatches: flow(function* ({
      page = 1,
      all = false,
      pageSize,
      q,
      student,
      fetchJuniorBatches = false,
      filterByCoachUsername,
      dropdown = false,
      includeUpcomingSession = false,
      excludeMergedBatches,
      ignoreEndAt,
      daysBefore,
    }: {
      page?: number;
      all?: boolean;
      pageSize?: number;
      q?: string;
      student?: string;
      fetchJuniorBatches?: boolean;
      filterByCoachUsername?: string;
      dropdown?: boolean;
      includeUpcomingSession?: boolean;
      excludeMergedBatches?: boolean;
      ignoreEndAt?: boolean;
      daysBefore?: number;
    }) {
      self.loading = true;
      try {
        const response = yield listBatches({
          page: page,
          all: all,
          pageSize,
          q,
          student,
          fetchJuniorBatches,
          filterByCoachUsername,
          dropdown,
          includeUpcomingSession,
          excludeMergedBatches,
          ignoreEndAt,
          daysBefore,
        });
        if (response.problem) {
          return;
        }
        if (dropdown) {
          self.dropdownBatches = response.data.results;
        } else {
          self.batches.clear();
          self.batches = response.data.results;
        }
        self.can_view_all_batches = response.data.can_view_all_batches;
        self.page = response.data.page;
        self.loaded = true;
      } finally {
        self.loading = false;
      }
    }),
    listSeniorCoachBatches: flow(function* ({
      page = 1,
      all = false,
      pageSize,
      q,
      student,
      filterByCoachUsername,
      dropdown = false,
    }: {
      page?: number;
      all?: boolean;
      pageSize?: number;
      q?: string;
      student?: string;
      filterByCoachUsername?: string;
      dropdown?: boolean;
    }) {
      self.seniorCoachBatchesLoading = true;
      try {
        const response = yield listBatches({
          page: page,
          all: all,
          pageSize,
          q,
          student,
          fetchJuniorBatches: true,
          filterByCoachUsername,
          dropdown,
        });
        if (response.problem) {
          return;
        }
        if (dropdown) {
          self.dropdownBatches = cast(response.data.results);
        } else {
          self.seniorCoachBatches.clear();
          self.seniorCoachBatches = cast(response.data.results);
        }
        self.count = response.data.count;
        self.can_view_all_batches = response.data.can_view_all_batches;
        self.page = response.data.page;
        self.seniorCoachBatchesLoaded = true;
      } finally {
        self.seniorCoachBatchesLoading = false;
      }
    }),
    fetchNewPremiumStudentBatch: flow(function* () {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchNewPremiumStudentBatch();
        if (response.problem) {
          throw response.originalError;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),

    fetchTournamentBatchSuggestions: flow(function* (sprintName, timingsStartAt) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchTournamentBatchSuggestions(sprintName, timingsStartAt);
        if (response.problem) {
          throw response.originalError;
        }
        if (response.status === 200) {
          return response.data;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchBatchesStartingSoon: flow(function* () {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchBatchesStartingSoon();
        if (response.problem) {
          throw response.originalError;
        }
        if (response.status === 200) {
          self.batchesStartingSoon = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    listPastBatches: flow(function* () {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchMyBatchHistory();
        if (response.problem) return;
        self.coachPastBatches = response.data;
      } finally {
        self.loading = false;
      }
    }),
    getBatchNextSessionDatetime: flow(function* (batchName: string) {
      const response: ApiResponse<any> = yield getBatchNextSessionDatetime(batchName);

      if (response.problem) return;
      return response.data.session_date;
    }),
    fetchBatchStudentLearningObjectives: flow(function* (
      { lookupValue, unitName, lookupField }: { lookupValue: string; unitName: string; lookupField?: string },
      config?: AxiosRequestConfig
    ) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchStudentLearningObjectives(
          {
            lookupValue,
            unitName,
            includeBatch: true,
            lookupField,
          },
          config
        );
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.batchStudentLearningObjectives = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
  }))
  .views((self) => ({
    get studentCount() {
      return self.batches.reduce((acccumulator, currVal) => {
        return acccumulator + currVal.batch_students.length;
      }, 0);
    },
  }));

export default BatchStore;

export const BatchMergeStore = types
  .model('BatchMergeStore', {
    movedBatchStudents: types.array(BatchStudent),
    mergeSuggestionsBatches: types.array(SuggestedBatchLite),
    allPossibleBatchMergeSuggestions: types.array(types.array(SuggestedBatchMerge)),
    unmergeableBatches: types.array(UnmergeableBatch),
    unmergeableBatchesPage: types.maybe(PageStore),
    scheduledBatchMerges: types.array(types.array(ScheduledBatchMerge)),
    batchMergeHistory: types.array(BatchMergeModel),
    batchMergeHistoryPage: types.maybe(PageStore),
    showForceMerge: false,
    mergeSuggestionsErrorMsg: '',
    creating: false,
    createErrors: '',
    cancellingBatchMerge: false,
    cancelMergeError: '',
    loading: false,
  })
  .actions((self) => ({
    prepareForMerge() {
      self.createErrors = '';
      self.showForceMerge = false;
      self.mergeSuggestionsErrorMsg = '';
    },
    mergeBatches: flow(function* ({
      mergeFromBatchName,
      mergeToBatchName,
      forceMerge = false,
      mergeAt = null,
    }: {
      mergeFromBatchName: string;
      mergeToBatchName: string;
      forceMerge: boolean;
      mergeAt: string | null;
    }) {
      try {
        self.creating = true;
        self.createErrors = '';
        self.movedBatchStudents.clear();
        const response: ApiResponse<any> = yield mergeBatches({
          mergeFromBatchName,
          mergeToBatchName,
          forceMerge,
          mergeAt,
        });
        if (response.status === 200) {
          if (response.data && response.data.length > 0) {
            self.movedBatchStudents = cast(response.data);
          }
        } else if (response.problem) {
          if (response.status === 400) {
            let error_msg = cast(response.data.error);
            if (error_msg === 'MergedBatchRecentlyMerged') {
              self.createErrors = 'Merged batch was part of a merge in last 15 days.';
              self.showForceMerge = true;
            } else {
              self.createErrors = error_msg;
            }
          } else {
            let commonStore = getEnv(self).commonStore;
            if (commonStore) {
              commonStore.setNetworkProblem(response.problem);
            }
          }
          return false;
        }
        return true;
      } finally {
        self.creating = false;
      }
    }),
    cancelMerge: flow(function* ({ batchMergeId }: { batchMergeId: number }) {
      try {
        self.cancellingBatchMerge = true;
        self.cancelMergeError = '';
        const response: ApiResponse<any> = yield cancelBatchMerge({ batchMergeId });
        if (response.problem) {
          if (response.status === 400) {
            self.cancelMergeError = cast(response.data.error);
          } else {
            let commonStore = getEnv(self).commonStore;
            if (commonStore) {
              commonStore.setNetworkProblem(response.problem);
            }
          }
          return false;
        }
        return true;
      } finally {
        self.cancellingBatchMerge = false;
      }
    }),
    fetchMergeBatchSuggestions: flow(function* ({
      mergeFromBatchName,
      timingsStartAt,
    }: {
      mergeFromBatchName: string;
      timingsStartAt: string | null;
    }) {
      try {
        self.loading = true;
        self.mergeSuggestionsErrorMsg = '';
        self.mergeSuggestionsBatches.clear();
        const response: ApiResponse<any> = yield fetchMergeBatchSuggestions(mergeFromBatchName, timingsStartAt);
        if (response.status === 200) {
          self.mergeSuggestionsBatches = response.data;
          self.mergeSuggestionsErrorMsg = '';
        } else if (response.status === 400) {
          self.mergeSuggestionsErrorMsg = cast(response.data.error);
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchScheduledBatchMerges: flow(function* ({
      unitId = null,
      date = null,
      classesPerWeek = 1,
      subscriptionBased = false,
    }: {
      unitId: number | null;
      date: string | null;
      classesPerWeek: number;
      subscriptionBased: boolean;
    }) {
      try {
        self.loading = true;
        self.scheduledBatchMerges.clear();
        const response: ApiResponse<any> = yield fetchScheduledBatchMerges(
          unitId,
          date,
          classesPerWeek,
          subscriptionBased
        );
        if (response.status === 200) {
          self.scheduledBatchMerges = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchBatchMergeHistory: flow(function* ({
      batchName = null,
      fromDate = null,
      tillDate = null,
      page = 1,
      pageSize = 50,
    }: {
      batchName: string | null;
      fromDate: string | null;
      tillDate: string | null;
      page: number;
      pageSize: number;
    }) {
      try {
        self.loading = true;
        self.batchMergeHistory.clear();
        const response: ApiResponse<any> = yield fetchBatchMergeHistory(batchName, fromDate, tillDate, page, pageSize);
        if (response.status === 200) {
          self.batchMergeHistory = cast(response.data.results);
          self.batchMergeHistoryPage = cast(response.data.page);
        }
      } finally {
        self.loading = false;
      }
    }),

    downloadBatchMergeHistory: flow(function* ({
      batchName = null,
      fromDate = null,
      tillDate = null,
    }: {
      batchName: string | null;
      fromDate: string | null;
      tillDate: string | null;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield downloadBatchMergeHistory(batchName, fromDate, tillDate);
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'BatchMergeHistory.csv');
          document.body.appendChild(link);
          link.click();
        }
      } finally {
        self.loading = false;
      }
    }),

    fetchAllMergeBatchSuggestions: flow(function* ({
      unitId = null,
      date = null,
      classesPerWeek = 1,
      subscriptionBased,
    }: {
      unitId: number | null;
      date: string | null;
      classesPerWeek: number;
      subscriptionBased: boolean;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchAllMergeBatchSuggestions(
          unitId,
          date,
          classesPerWeek,
          subscriptionBased
        );
        if (response.status === 200) {
          self.allPossibleBatchMergeSuggestions = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),

    fetchUnmergeableBatches: flow(function* ({
      unitId = null,
      date = null,
      classesPerWeek = 1,
      subscriptionBased = false,
      page = 1,
      pageSize = 10,
    }: {
      unitId: number | null;
      date: string | null;
      classesPerWeek: number;
      subscriptionBased: boolean;
      page: number;
      pageSize: number;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchUnmergeableBatches(
          unitId,
          date,
          classesPerWeek,
          subscriptionBased,
          page,
          pageSize
        );
        if (response.status === 200) {
          self.unmergeableBatches = cast(response.data.results);
          self.unmergeableBatchesPage = cast(response.data.page);
        }
      } finally {
        self.loading = false;
      }
    }),
  }));

export interface IBatchMergeStore extends Instance<typeof BatchMergeStore> {}
