import { create, ApiResponse } from 'apisauce';
import { authStore, commonStore } from '../store/singletons';
import axios, { CancelToken } from 'axios';
import Config from '../config';
import * as Sentry from '@sentry/browser';
import { IStudentContact } from 'components/StudentProfilePopup/CommunicationForm';

export const api = create({
  baseURL: Config.API_BASE_URL,
  timeout: 10000,
});

api.addRequestTransform((request) => {
  if (request.url !== `auth/token/login/`) {
    if (commonStore.token) {
      request.headers['Authorization'] = `Token ${commonStore.token}`;
    }
    const impersonateUser = window.localStorage.getItem('impersonate');
    if (impersonateUser) request.headers['x-impersonate-user'] = impersonateUser;
  }
});

api.addResponseTransform((response) => {
  if (response.status === 401) {
    authStore.logout();
  }
  if (response.problem) {
    if (response.status !== 401 && response.status !== 400 && response.status !== 403) {
      Sentry.withScope((scope) => {
        scope.setExtra('response', response);
        const errorMsg = 'url: ' + response.config?.url + ' problem: ' + response.problem;
        Sentry.captureException(new Error(errorMsg));
      });
    }
  }
});

interface LoginProps {
  username: string;
  password: string;
  token?: string;
}

interface OtpProps {
  phone?: string;
  username?: string;
  trials: number;
  login_intent?: boolean;
}

export const login = ({ username, password, token }: LoginProps) =>
  api.post(`auth/token/login/`, { username, password, token });

export const loginWithJWTToken = (token: string) => api.post(`users/auth/login-with-token`, { token });

export const postOtp = ({ phone, trials, login_intent }: OtpProps) =>
  api.post(`users/otp/request`, { phone, trials, login_intent });

export const listOTP = () => api.get(`users/otp/list`);

export const getCurrentUser = () => api.get(`users/me/`);

export const fetchHomepageBanners = (interaction: string) => api.get(`homepage-banner/`, { interaction });

export const getMaskedPhone = ({ username }: { username: string }) => api.get(`users/${username}/masked-phone/`);

export const getFirebaseToken = () => api.get(`users/firebase-token/`);

interface UserProps {
  name: string;
  profile_image_url?: string;
}

export const saveCurrentUser = ({ name, profile_image_url }: UserProps) =>
  api.patch(`users/me/`, { name, profile_image_url });

export const listSessions = ({
  upcoming = false,
  demo = undefined,
  page,
  pageSize,
  test = undefined,
  completed = undefined,
}: {
  upcoming: boolean;
  demo?: boolean;
  page: number;
  pageSize?: number;
  test?: boolean;
  completed?: boolean;
}) =>
  api.get(`my/tutor_sessions/`, {
    upcoming,
    demo,
    page,
    pageSize,
    test,
    completed,
  });

export const listUnfinishedSessions = ({ demo, test }: { demo?: boolean; test?: boolean }) =>
  api.get(`my/tutor_sessions/unfinished/`, {
    demo,
    test,
  });

interface SessionProps {
  session_name: string;
}

export interface SessionFeedback {
  rating: number;
  issues: string;
  category: string;
}

interface SessionCompletedProps extends SessionProps {
  feedbacks?: Array<SessionFeedback>;
}

export const getSessionDetail = ({ session_name }: SessionProps) => api.get(`tutor_sessions/${session_name}/`);
export const getMySessionDetail = ({ session_name }: SessionProps) => api.get(`my/tutor_sessions/${session_name}/`);
export const getMyUpcomingInsightCalls = () => api.get('student/upcoming-insight-calls/');
export const setSessionCompleted = ({ session_name, feedbacks }: SessionCompletedProps) =>
  api.post(`my/tutor_sessions/${session_name}/completed/`, { feedbacks });

interface BatchProps {
  batch?: string;
  daysBefore: number;
  pageNumber: number;
  demo?: boolean;
  test?: boolean;
  sprint?: string;
  pageSize?: number;
}

export const getCompletedSessions = ({ batch, daysBefore, pageNumber, demo, test, sprint, pageSize }: BatchProps) =>
  api.get(`my/completed_sessions/`, { batch, daysBefore, page: pageNumber, demo, test, sprint, pageSize });

export const getCompletedSession = ({ session_name }: SessionProps) =>
  api.get(`my/completed_sessions/${session_name}/`);

export const fetchSessionActivityHistory = ({ session_name }: SessionProps) =>
  api.get(`my/completed_sessions/${session_name}/activity-history/`);

export const listActiveSessions = () => api.get('active_sessions/');

export const getPreloaderHtml = () =>
  api.get(
    `browserchecks/preloader_html`,
    {},
    {
      headers: {
        accept: 'text/html',
      },
    }
  );

export const getCodingHtml = ({
  activity_uuid,
  disable_preview,
  readonly_workspace,
}: {
  activity_uuid: string;
  disable_preview?: boolean;
  readonly_workspace?: boolean;
}) =>
  api.get(
    `coding_activities/loader_html/${activity_uuid}`,
    { disable_preview: disable_preview ? 'yes' : 'no', readonly_workspace: readonly_workspace ? 'yes' : 'no' },
    {
      headers: {
        accept: 'text/html',
      },
      timeout: 15000,
    }
  );

export const getChannelVersions = ({ channel }: { channel: string }) =>
  api.get(`coding_activities/channel_versions/${channel}`);

export const tagChannelVersion = ({
  channel,
  versionId,
  versionTag,
}: {
  channel: string;
  versionId: string;
  versionTag: string;
}) => api.post(`coding_activities/channel_versions/${channel}/tag`, { version_id: versionId, version_tag: versionTag });

export const saveBrowserCheck = ({ browserCheck }: { browserCheck: any }) =>
  api.post(`browserchecks/ingest`, browserCheck);

interface FileResponse {
  url: string;
  public_url: string;
}

export const uploadFile = async (
  filename: string,
  blob: Blob,
  onUploadProgress?: (progressEvent: any) => void,
  cancelToken?: CancelToken
) => {
  const response: ApiResponse<FileResponse> = await api.post(`files/presigned`, {
    filename,
    filetype: blob.type,
  });
  if (response.problem) {
    return;
  }
  if (!response.data) {
    return;
  }

  const signedUrl = response.data.url;

  const options = {
    headers: {
      'Content-Type': blob.type,
      'x-amz-acl': 'public-read',
    },
    onUploadProgress,
    cancelToken,
  };
  await axios.put(signedUrl, blob, options);
  return response.data.public_url;
};

export const listBatches = ({
  all = false,
  page = 1,
  pageSize = 50,
  q,
  student,
  fetchJuniorBatches = false,
  filterByCoachUsername,
  dropdown = false,
  includeUpcomingSession = false,
  excludeMergedBatches = false,
  ignoreEndAt = false,
  daysBefore,
}: {
  page: number;
  all: boolean;
  pageSize?: number;
  q?: string;
  student?: string;
  fetchJuniorBatches?: boolean;
  filterByCoachUsername?: string;
  dropdown?: boolean;
  includeUpcomingSession?: boolean;
  excludeMergedBatches?: boolean;
  ignoreEndAt?: boolean;
  daysBefore?: number
}) =>
  api.get(`my/batches/`, {
    q,
    page,
    pageSize,
    all: Number(all),
    student,
    fetchJuniorBatches,
    filterByCoachUsername,
    dropdown,
    includeUpcomingSession,
    excludeMergedBatches,
    ignoreEndAt,
    daysBefore
  });

export const fetchStudentLearningObjectives = (
  {
    lookupValue,
    unitName,
    includeBatch,
    lookupField,
  }: { lookupValue: string; unitName?: string; includeBatch?: boolean; lookupField?: string },
  config?: any
) => api.get(`my/batches/${lookupValue}/student_learning_objectives/`, { unitName, includeBatch, lookupField }, config);

export const batchCreateSlackChannel = ({ batch_name }: { batch_name: string }, config?: any) =>
  api.post(`my/batches/${batch_name}/create_slack/`, {}, config);

export const loadNotifications = () => api.get(`notifications/scheduled`);

export const zohoLookup = ({ lookup }: { lookup: { [key: string]: string } }) => api.post(`zoho/lookup`, { lookup });

export const fetchActivityDetail = ({ activity_uuid }: { activity_uuid: string }) =>
  api.get(`activities/${activity_uuid}/`);

export const fireStats = ({
  timing = undefined,
  incr = undefined,
  ms = undefined,
}: {
  timing?: string;
  incr?: string;
  ms?: number;
}) => api.post(`stats`, { timing, incr, ms });

export const fetchMyProjects = ({ page }: { page: number }) => api.get('my/projects/', { page, pageSize: 2 });

export const fetchMyReflections = () => api.get('files/my/video-recordings/');

export const fetchUnitBackground = ({ sprintName }: { sprintName: string }) =>
  api.get(`sprints/${sprintName}/unit-backgrounds/`);

export const listMasterclasses = ({
  upcoming = false,
  page,
  pageSize,
  test = undefined,
}: {
  upcoming: boolean;
  page: number;
  pageSize?: number;
  test?: boolean;
}) =>
  api.get(`my/masterclass_sessions/`, {
    upcoming,
    page,
    pageSize,
    test,
  });

export const listPremiumMasterclasses = ({
  upcoming = false,
  page,
  pageSize,
  test = undefined,
}: {
  upcoming: boolean;
  page: number;
  pageSize?: number;
  test?: boolean;
}) =>
  api.get(`my/masterclass_sessions/premium/`, {
    upcoming,
    page,
    pageSize,
    test,
  });

export const getMasterclassDetail = ({ session_name }: SessionProps) =>
  api.get(`my/masterclass_sessions/${session_name}/`);

export const askMasterclassQuestion = ({ session_name, question }: { session_name: string; question: string }) =>
  api.post(`/my/masterclass_sessions/${session_name}/ask-question/`, { question });

export const postProjectToken = ({ id, project_level_name }: { id: number; project_level_name: string }) =>
  api.post(`p5js_activities/p5jstoken_patch`, { id, project_level_name });

interface guestLoginProps {
  kidName: string;
  grade: string;
  extraInfo?: Map<string, string>;
  phone: string;
  school?: string;
}

export const checkUserWithPhoneExists = (phone: string) => api.get('users/phone-exists/', { phone });

export const createGuestApi = (props: guestLoginProps) => api.post('guest/', props);

export const guestLogin = (props: guestLoginProps) => api.post('guest/login/', props);

export const updateGuest = (username: string, props: guestLoginProps) => api.patch(`guest/${username}/`, props);

export const createReferral = (props: guestLoginProps) => api.post(`referrals/referral-code/`, { ...props });

export const fetchMyReferrals = () => api.get(`referrals/my-referrals/`);

export const fetchReferralsForThisMonth = () => api.get(`referrals/referrals-this-month/`);

export const sendWhatsAppMessage = (code: string) => api.post(`referrals/notify-referrer/`, { code });

export const getMyReferralsRemaining = () => api.get('referrals/my/referrals-remaining/');

export const getReferrerInfo = () => api.get('referrals/my/user-info/');

export const masterClassRegister = ({ sessionName }: { sessionName: string }) =>
  api.post(`masterclass/${sessionName}/register/`, { session_name: sessionName });

export const masterClassTestRegister = ({ sessionName }: { sessionName: string }) =>
  api.post(`masterclass/${sessionName}/register_anonymous_for_testing/`, { session_name: sessionName });

export const listOpenMasterClassSession = ({
  masterclassName,
  subject,
}: {
  masterclassName?: string | null;
  subject?: string | null;
}) => api.get('masterclass_open/', { name: masterclassName, subject });

export const getOpenMasterClassSession = ({ masterclassName, school }: { masterclassName: string; school?: string }) =>
  api.get(`masterclass_open/${masterclassName}/`, { school });

export const getWhatsAppTemplatesList = () => api.get('whatsapp/templates/list');

export const sendWhatsAppTemplateMessages = (templateData: any) => api.post('whatsapp/templates/send', templateData);

export const fetchStudentReports = (params?: {}) => api.get('student_reports/', params);

export const fetchReportsToBeSent = (params?: {}) => api.get('student_reports/filtered-list/', params);

export const getStudentReport = (report_name: string) => api.get(`student_reports/${report_name}/`);

export const fetchAllReportsByStudent = (params: { studentUsername: string; pageSize?: number; page?: number }) =>
  api.get('student_reports/all-reports', params);

export const publishStudentReport = ({
  type,
  report_name,
  parameters,
}: {
  type: string;
  report_name: string;
  parameters: any;
}) =>
  api.patch(`student_reports/${report_name}/`, {
    type,
    report_name,
    parameters,
  });

export const markStudentReportSeen = (report_name: string) =>
  api.post(`student_reports/${report_name}/mark-report-seen/`);

export const archiveStudentReport = (report_name: string, archiveReason: string) =>
  api.post(`student_reports/${report_name}/archive-report/`, { archiveReason });

export const getStudent = (username: string) => api.get(`student/${username}/`);

export const updateStudentDetails = (
  student_username: string,
  params: {
    gender?: string;
    name?: string;
    grade?: string;
    address?: string;
    city?: string;
    pincode?: string;
    contacts?: IStudentContact[];
  }
) => api.patch(`student/${student_username}/`, params);

export const submitEmpathyFormData = (params: {}) => api.post('empathy/', { form_data: params });

export const getEmpathyFormData = ({
  student,
  subject,
  coach,
}: {
  student: string;
  subject?: string;
  coach?: string;
}) => api.get('empathy/', { student, subject, coach });

export const getDefaultEmpathyFormData = () => api.get('empathy/default-data/');

interface SignupValidateParams {
  parentName: string;
  parentEmail: string;
  phone: string;
  kidName: string;
  kidSchool: string;
  grade: string;
  extraInfo: Map<string, string>;
  timezone: string;
}

export const signupPrevalidate = (params: SignupValidateParams) => api.post(`auth/student/prevalidate/`, params);

interface SignupProps extends SignupValidateParams {
  otp?: string;
}

export const signup = (params: SignupProps) => api.post(`auth/student/signup/`, params);

export const checkScienceEligibility = () => api.get('users/my/science-invite/');

export const submitScienceInviteResponse = (response: string, key: string) =>
  api.post('users/my/science-invite/submit', { response, key });

export const submitContentissue = (category: string, description: string, activity_uuid: string) =>
  api.post('content-issue/', { category, description, activity_uuid });

export const getReferralCampaign = () => api.get('referrals/');

export const getReferralLeaderboard = (campaign: string) => api.get(`referrals/${campaign}/leaderboard/`);

export const getReferralLinkOnWhatsapp = (campaign: string) => api.post(`referrals/${campaign}/share-on-whatsapp/`);

export const getReferralLink = (campaign: string) => api.get(`referrals/${campaign}/referral-link/`);

export const getcurrentReferrerDetails = (campaign: string) => api.get(`referrals/${campaign}/referrer-detail/`);

export const getBadges = (username?: string) => api.get('rewards/badges/', { username });

export const checkPermissions = (permissions: Array<string>) => api.post('users/my/permissions', { permissions });

export const checkHasPhone = (usernames: Array<string>) => api.post('users/check-has-phone/', { usernames });

interface IAddNoteProps {
  activity_room?: string;
  category: string;
  description: string;
  added_for: string;
}

export const addNote = (props: IAddNoteProps) => api.post('/notes/', props);

interface IGetNotesProps {
  addedFor: string;
  daysBefore?: number;
}

export const getStudentNotes = ({ addedFor, daysBefore }: IGetNotesProps) =>
  api.get(`/notes/grouped-notes/${addedFor}/`, { daysBefore });

interface IUpdateParentDetailProps {
  name: string;
  phone: string;
  relationship: string;
}

export const updateParentDetail = ({ name, phone, relationship }: IUpdateParentDetailProps) =>
  api.post('/parents/', { name, phone, relationship });

export const fetchBatchStudentProjects = ({
  batchStudent,
  daysBefore,
  pageNumber,
  pageSize,
}: {
  batchStudent: string;
  daysBefore: number;
  pageNumber: number;
  pageSize?: number;
}) => api.get(`/projects/`, { daysBefore, page: pageNumber, batchStudent, pageSize });

export const fetchStudentProjects = ({
  studentUsername,
  pageNumber,
  pageSize,
}: {
  studentUsername: string;
  pageNumber: number;
  pageSize: number;
}) => api.get(`/projects/`, { page: pageNumber, pageSize, studentUsername });

export const fetchStudentCodingActivity = ({
  studentUsername,
  projectName,
}: {
  studentUsername: string;
  projectName: string;
}) => api.get(`/projects/coding_activity/`, { studentUsername, projectName });

export const fetchStudentProjectLink = ({
  studentUsername,
  projectName,
}: {
  studentUsername: string;
  projectName: string;
}) => api.get(`/projects/link/`, { studentUsername, projectName });

export const fetchUnitReportLink = ({ studentUsername, unitName }: { studentUsername: string; unitName?: string }) =>
  api.get(`/student_reports/link/`, { studentUsername, unitName });

export const fetchBatchStudentTranscodedVideos = ({
  batchStudent,
  pageNumber,
}: {
  batchStudent: string;
  pageNumber: number;
}) => api.get(`/transcoded-videos/`, { batchStudent, page: pageNumber });

export const fetchTranscodedVideo = ({ pks }: { pks: string }) => api.get(`/transcoded-videos/video/`, { pks });

export const getReportsCount = () => api.get('student_reports/reports-count/');
export const submitVideoListForStitching = ({
  videoList,
  stitchVideosClassIdentifier,
  otherParams,
}: {
  videoList: string[];
  stitchVideosClassIdentifier: string;
  otherParams: { [key: string]: string };
}) => api.post('transcoded-videos/stitch-recordings/', { videoList, stitchVideosClassIdentifier, otherParams });

export const fetchStudent = ({ studentUsername }: { studentUsername: string }) =>
  api.get(`/my/students/${studentUsername}`);

export const saveStudentReport = (report_name: string, parameters: any) =>
  api.patch(`/student_reports/${report_name}/save-report/`, { parameters });

export const regenerateReport = (report_name: string) => api.post(`/student_reports/${report_name}/regenerate/`);

export const fetchNewPremiumStudentBatch = () => api.get('batches/fetch-new-premium-student-batch');

export const fetchTournamentBatchSuggestions = (sprintName: string, timingsStartAt: string) =>
  api.get('/my/batches/tournament-batch-suggestions/', { sprintName, timingsStartAt });

export const fetchMergeBatchSuggestions = (mergeFromBatchName: string, timingsStartAt: string | null) =>
  api.get(`/batch-merge/${mergeFromBatchName}/suggestions/`, { timingsStartAt });

export const fetchScheduledBatchMerges = (
  unitId: number | null,
  date: string | null,
  classesPerWeek: number,
  subscriptionBased: boolean
) => api.get(`/batch-merge/scheduled/`, { unitId, date, classesPerWeek, subscriptionBased });

export const fetchBatchMergeHistory = (
  batchName: string | null,
  fromDate: string | null,
  tillDate: string | null,
  page: number,
  pageSize?: number
) =>
  api.get(`/batch-merge/history/`, {
    batchName: batchName,
    fromDate: fromDate,
    tillDate: tillDate,
    page: page,
    pageSize: pageSize,
  });

export const downloadBatchMergeHistory = (batchName: string | null, fromDate: string | null, tillDate: string | null) =>
  api.get(`/batch-merge/history/`, {
    batchName: batchName,
    fromDate: fromDate,
    tillDate: tillDate,
    download: 'true',
  });

export const fetchAllMergeBatchSuggestions = (
  unitId: number | null,
  date: string | null,
  classesPerWeek: number,
  subscriptionBased: boolean
) => api.get(`/batch-merge/suggestions/`, { unitId, date, classesPerWeek, subscriptionBased });

export const fetchUnmergeableBatches = (
  unitId: number | null,
  date: string | null,
  classesPerWeek: number,
  subscriptionBased: boolean,
  page: number,
  pageSize: number
) => api.get(`/batch-merge/unmergeable-batches/`, { unitId, date, classesPerWeek, subscriptionBased, page, pageSize });

export const addCallbackRequest = (batchStudentName: string, date: string) =>
  api.patch(`/batch_students/${batchStudentName}/callback/`, { callbackDate: date });

export const fetchBatchesStartingSoon = () => api.get('batches/starting-soon/');

export const mergeBatches = ({
  mergeFromBatchName,
  mergeToBatchName,
  forceMerge,
  mergeAt,
}: {
  mergeFromBatchName: string;
  mergeToBatchName: string;
  forceMerge: boolean;
  mergeAt: string | null;
}) =>
  api.post('batch-merge/merge-batches/', {
    mergeFromBatchName,
    mergeToBatchName,
    mergeAt,
    forceMerge,
  });

export const cancelBatchMerge = ({ batchMergeId }: { batchMergeId: number }) =>
  api.patch(`batch-merge/${batchMergeId}/cancel/`);

export const submitParentFeedback = (requestData: any) => api.post('/parents/submit-parent-feedback/', requestData);

export const getInsightCallSession = (insightCallName: string) =>
  api.get(`/my/insight-call-sessions/${insightCallName}/`);

export const getScript = (insightCallName: string) => api.get(`/my/insight-calls/${insightCallName}/script/`);

export const cancelInsightCall = (insightCallName: string) =>
  api.post(`/my/insight-calls/${insightCallName}/cancel-office-hour/`);

export const saveResponses = (insightCallName: string, responses: Map<string, string>) =>
  api.post(`/my/insight-calls/${insightCallName}/responses/`, Object.fromEntries(responses));

export const getPresentationVideo = (insightCallName: string) =>
  api.get(`/my/insight-call-sessions/${insightCallName}/presentation-video/`);

export const submitInsightCallFeedback = (requestData: any) => api.post('/my/insight-call-feedbacks/', requestData);

export const submitInsightCallFeedbackForSkip = (requestData: any) =>
  api.post('/my/insight-call-feedbacks/skip-insight-call/', requestData);

export const fetchParentFeedbackStatuses = (feedbackType: string) =>
  api.get('/my/insight-call-feedbacks/parent-update-statuses/', { feedbackType });

export const fetchInsightCallFeedbackFormDetails = (insightCallName: string) =>
  api.get(`/my/insight-calls/${insightCallName}/feedback-form-details/`);

export const fetchBatchByBatchStudentName = (batchStudentName: string) =>
  api.get('batches/batch-by-batch-student/', { batchStudentName });

export const hasEmpathy = (params: string[]) => api.get(`student/has-empathy/?params=${params}`);

export const validateStudentProfile = (username: string, fields: { [key: string]: string }) =>
  api.post(`student/${username}/validate-profile/`, fields);

export const sendPCMBookingLink = (batchStudentName: string, alternateNumber?: string) =>
  api.post(`batch_students/${batchStudentName}/send-pcm-booking-link/`, { alternateNumber });

export const fetchRegisteredSprints = () => api.get('my/tutor_sessions/registered-sprints/');

export const fetchCatchupSessions = ({ missedCatchupSessions }: { missedCatchupSessions?: boolean }) =>
  api.get('my/catchup_tutor_sessions/', { missedCatchupSessions });

export const registerForCatchupSession = (sessionName: string) =>
  api.post(`my/catchup_tutor_sessions/${sessionName}/add-student/`);

export const clickToCallMakeCall = (toUsername: string) =>
  api.post('notifications/click-to-call', { toUsername }, { timeout: 30000 });

export const ivrNoResponse = (issueId: string, status: string) =>
  api.post('issues/ivr-no-response', { issueId, status });

export const fetchSessionRecordingForFeedback = (sessionRecordingName: string) =>
  api.get(`session-recording-quality/${sessionRecordingName}/`);

export const postSessionQualityThread = (sessionRecordingName: string, content: string, videoTimestamp: number) =>
  api.post(`session-recording-quality/${sessionRecordingName}/add-thread/`, { content, videoTimestamp });

export const postSessionQualityReply = (sessionQualityThreadName: string, content: string) =>
  api.post(`session-recording-quality/add-reply/`, { content, sessionQualityThreadName });

export const markSessionQualityThreadAcknoledged = (sessionQualityThreadName: string) =>
  api.post(`session-recording-quality/mark-thread-acknowledged/`, { sessionQualityThreadName });

export const getSessionClipSuggestions = (sessionRecordingName: string) =>
  api.get(`session-recording-quality/${sessionRecordingName}/clips-suggestions/`);

export const optimizeVideo = (sessionRecordingName: string) =>
  api.get(`/blackbox/session-recording/${sessionRecordingName}/optimize_video/`);

export const fetchInsightCallRescheduleReasons = () => api.get('my/insight-call-sessions/reasons/');
export const submitInsightCallRescheduleRequest = ({
  reschedule_reason,
  comments,
  session_name,
}: {
  reschedule_reason: string;
  comments: string;
  session_name: string;
}) => api.post(`my/insight-call-sessions/${session_name}/reschedule/`, { reschedule_reason, comments });

export const submitOrientationSessionRescheduleRequest = ({
  reschedule_reason,
  comments,
  session_name,
}: {
  reschedule_reason: string;
  comments: string;
  session_name: string;
}) => api.post(`ld-sessions/${session_name}/mark_reschedule/`, { reschedule_reason, comments });

export const getOpsGroupName = () => api.get('issues/ops-group/');
export const getBatchArtworks = (projectName: string, batchName: string) =>
  api.get(`batches/${batchName}/art-gallery/${projectName}`);

export const studentSlackConversation = (batchStudentName: string) =>
  api.get(`/whatsapp/student-slack-link/${batchStudentName}`);
interface createSessionClipProp {
  sessionRecordingName: string;
  start_at: number;
  end_at: number;
  studentSkill?: string;
  student?: string;
  crop_params?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

export const createSessionClip = ({
  sessionRecordingName,
  start_at,
  end_at,
  crop_params,
  studentSkill,
  student,
}: createSessionClipProp) =>
  api.post(`session-recording-quality/${sessionRecordingName}/create-clip/`, {
    start_at,
    end_at,
    crop_params,
    studentSkill,
    student,
  });

export const hasPendingPIC = (username: string) => api.get(`student/${username}/has-pending-pic/`);

export const hasPendingPayment = (username: string) => api.get(`student/${username}/has-pending-payment/`);

export const fetchStudentActivityDetail = ({ activity_uuid }: { activity_uuid: string }) =>
  api.get(`student-activities/${activity_uuid}/`);

export const fetchInsightCallDetail = (username: string) =>
  api.get(`insight-call/insight-call-details?username=${username}`);

export const fetchPartialPaymentDetail = (username: string) =>
  api.get(`payments/fetch-partial-pay-student-data?username=${username}`);

export const fetchStudentBatchDetails = (username: string) => api.get(`student/${username}/get-batch-details`);

export const fetchRegisteredMasterclassSessions = (username: string) =>
  api.get(`student/${username}/registered-masterclass-sessions/`);

export const fetchMyCoachesList = () => api.get(`student/my-coaches`);

export const fetchZohoFormFeedback = (insightCallName: string) =>
  api.get(`insight-call/zoho-form-feedback/${insightCallName}`);

export const fetchLDSessions = (
  completed: boolean,
  pageNumber: number,
  pageSize: number,
  reschedulingNeeded?: boolean,
  showRescheduleRequests?: boolean
) =>
  api.get(`ld-sessions/`, {
    completed: completed,
    page: pageNumber,
    pageSize: pageSize,
    reschedulingNeeded: reschedulingNeeded,
    showRescheduleRequests: showRescheduleRequests,
  });

export const fetchDailyReport = (reportName: string) => api.get(`student_reports/daily-report/${reportName}/`);

export const fetchDailyReportPreview = (sprintName: string) =>
  api.get(`student_reports/daily-report/${sprintName}/preview`);

export const fetchProjectDeadline = (batchName: string) => api.get(`batches_project_deadline/${batchName}/`);

export const fetchLatestSubscription = (username: string) => api.get(`users/${username}/subscription/`);

export const fetchUserSubscription = (username: string) => api.get(`subscriptions/user/${username}/`);

export const fetchSubscriptionsExpiringSoon = ({ page, pageSize }: { page?: number; pageSize?: number }) =>
  api.get('subscriptions/user/expiring-soon/', { page, pageSize });

export const fetchExpiredButActiveSubscriptions = ({ page, pageSize }: { page?: number; pageSize?: number }) =>
  api.get('subscriptions/user/expired-but-active/', { page, pageSize });

export const sendRenewalLink = (name: string, phone?: string) =>
  api.post(`subscriptions/user/${name}/send-renewal-link/`, { alternateNumber: phone });

export const fetchSubscriptionPlan = ({
  username,
  subscription,
  subject,
}: {
  username: string;
  subscription?: string;
  subject?: string;
}) => api.get(`subscriptions/user/plan/`, { username, subscription, subject });

export const setUserTimezone = ({ username, timezone }: { username: string; timezone: string }) =>
  api.post(`users/${username}/set-timezone/`, { timezone });

export const updateSessionAttendanceStatus = ({ status }: { status: string }) =>
  api.post(`my/tutor_sessions/update-attendance-status/`, { status });

export const studentSessionReplayInteraction = ({ sprint, course }: { sprint: string; course?: string }) =>
  api.post(`/mastery/student-interaction/`, { sprint, course });

export const aiTranscribe = (data: FormData) => {
  return api.post(`ai/transcribe`, data, { responseType: 'blob', timeout: 30000 });
};

export const getBatchNextSessionDatetime = (batchName: string) =>
  api.get(`batches/${batchName}/next-batch-session-date-time/`);

export default api;

(window as any).impersonate = function (username: string) {
  window.localStorage.impersonate = username;
  window.location.reload();
};
